import { queryTotalInfos } from '../requests';

const initPagination = {
  pageSize: 10,
  currentPage: 1,
  total: 0,
};

const Model = {
  namespace: 'teamStatistics',
  state: {
    singleTabledData: [],
    totalTabledData: [],
    pagination: initPagination,
  },
  effects: {
    *queryTotalInfos({ payload }, { call, put }) {
      const res = yield call(queryTotalInfos, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveTotalInfos',
          payload: res.data,
        });
      }
    },
  },
  reducers: {
    saveTotalInfos(state, { payload }) {
      const { pageNum, pageSize, total, dataInfos } = payload;
      const pagination = {
        currentPage: pageNum,
        pageSize,
        total,
      };
      return { ...state, totalTabledData: dataInfos, pagination };
    },
  },
};
export default Model;

import {
  getAuditData,
  queryAuditContentById,
  updateField,
  auditHuman,
  disableMediaStatus,
  auditContPoolBatchAudit,
} from '../requests';

const initPagination = {
  pageSize: 10,
  currentPage: 1,
  total: 0,
};

const Model = {
  namespace: 'audit',
  state: {
    articleAuditData: [],
    videoAuditData: [],
    expressAuditData: [],
    pagination: initPagination,
  },
  effects: {
    *getAuditData({ payload }, { call, put }) {
      const res = yield call(getAuditData, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveManualAuditData',
          payload: { data: res.data, itemType: payload.itemType },
        });
      }
      return res.data;
    },
    *auditHuman({ payload }, { call }) {
      return yield call(auditHuman, { ...payload });
    },
    *auditContPoolBatchAudit({ payload }, { call }) {
      return yield call(auditContPoolBatchAudit, { ...payload });
    },
    *updateField({ payload }, { call }) {
      return yield call(updateField, payload);
    },
    *queryAuditContentById({ payload }, { call }) {
      return yield call(queryAuditContentById, payload);
    },
    *disableMediaStatus({ payload }, { call }) {
      return yield call(disableMediaStatus, payload);
    },
  },
  reducers: {
    saveManualAuditData(state, { payload }) {
      const { pageNum, pageSize, total, dataInfos } = payload.data;
      const pagination = {
        currentPage: pageNum,
        pageSize,
        total,
      };
      if (payload.itemType === 'article') {
        return { ...state, articleAuditData: dataInfos, pagination };
      }
      if (payload.itemType === 'svideo') {
        return { ...state, videoAuditData: dataInfos, pagination };
      }
      if (payload.itemType === 'express') {
        return { ...state, expressAuditData: dataInfos, pagination };
      }
      return { ...state };
    },
  },
};
export default Model;

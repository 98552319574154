import myRequest from '@/services/myRequest';

// 添加角色
export async function addRole(params) {
  return myRequest('post', '/userRole/add', params, { requestType: 'json' });
}
// 编辑角色
export async function updateRole(params) {
  return myRequest('post', '/userRole/update', params, { requestType: 'json' });
}
// 删除角色
export async function deleteRole(params) {
  return myRequest('post', '/userRole/delete', params);
}
// 获取角色列表
export async function getRoleLists(params) {
  return myRequest('get', '/userRole/queryList', params);
}
// 获取子用户权限菜单
export async function getItemTree(params) {
  return myRequest('get', '/index/getItemTree', params);
}

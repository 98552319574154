import myRequest from '@/services/myRequest';

// 新建文章和视频
export async function createArticleAndVideo(params) {
  return myRequest('post', '/distribute/createContent', params, { requestType: 'json' });
}

// 编辑文章和视频
export async function editArticleAndVideo(params) {
  return myRequest('post', '/distribute/updateContent', params, { requestType: 'json' });
}

// 通过文章ID获取文章 编辑
export async function queryContentByInfoId(params) {
  return myRequest('get', '/selfContent/queryContentByInfoId', params);
}

// 获取预览链接
export async function getPreviewUrl(params) {
  return myRequest('get', '/selfBuild/getPreviewUrl', params);
}

// 检测正文敏感词
export async function checkContent(params) {
  return myRequest('post', '/selfBuild/checkContent', params, {
    requestType: 'json',
  });
}

// 获取预览文章
export async function previewByInfoId(params) {
  return myRequest('get', '/selfContent/previewByInfoId', params);
}

// 获取素材文章
export async function queryMaterialByInfoId(params) {
  return myRequest('get', '/material/queryMaterialByInfoId', params);
}

// 获取文章内图片地址
export async function getImgUrlByFileUrl(params) {
  return myRequest('post', '/upload/uploadResource', params);
}

// 获取nos桶视频信息
export async function getVideoInfo(params) {
  return myRequest('get', `/getVideoInfo/${params.url}`, { vinfo: '' });
}

// 查询平台账号
export async function queryAccountInfo(params) {
  return myRequest('get', '/platform/queryAccountInfo', params);
}

// 微信采集获取文章
export async function selfBuildCrawlerFromWeixin(url) {
  return myRequest(
    'post',
    '/selfBuild/crawlerFromWeixin',
    { info_url: url },
    { requestType: 'json' },
  );
}

import myRequest from '@/services/myRequest';

export async function getAccountGroupList(params) {
  return myRequest('get', '/accountGroup/queryList', params);
}

export async function addAccountGroup(params) {
  return myRequest('post', '/accountGroup/add', params, { requestType: 'json' });
}

export async function editAccountGroup(params) {
  return myRequest('post', '/accountGroup/update', params, { requestType: 'json' });
}

// 查询平台账号
export async function getPlatformInfo(params) {
  return myRequest('get', '/platform/queryPublicAccount', params);
}

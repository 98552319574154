import myRequest from '@/services/myRequest';

// 内容管理列表
export async function getContentList(params) {
  return myRequest('get', '/auditContent/queryAuditContent', params);
}

// 获取详情
export async function getContentById(params) {
  return myRequest('get', '/auditContent/queryAuditContentById', params);
}

// 上下架
export async function updateStatusById(params) {
  return myRequest('post', '/auditContent/updateStatusById', params);
}

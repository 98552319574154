import {
  addSonUser,
  updateSonUser,
  getSonUserLists,
  getItemTree,
  addAdminGroup,
  resetPassword,
  queryAllRole,
} from '../requests';

const initPagination = {
  pageSize: 10,
  currentPage: 1,
  total: 0,
};

const Model = {
  namespace: 'accountManagement',
  state: {
    tabledData: [],
    pagination: initPagination,
  },
  effects: {
    *addSonUser({ payload }, { call }) {
      return yield call(addSonUser, payload);
    },
    *updateSonUser({ payload }, { call }) {
      return yield call(updateSonUser, payload);
    },
    *getSonUserLists({ payload }, { call, put }) {
      const res = yield call(getSonUserLists, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveSonUserLists',
          payload: res.data,
        });
      }
    },
    *getItemTree({ payload }, { call }) {
      return yield call(getItemTree, payload);
    },
    *addAdminGroup({ payload }, { call }) {
      return yield call(addAdminGroup, payload);
    },
    *resetPassword({ payload }, { call }) {
      return yield call(resetPassword, payload);
    },
    *queryAllRole({ payload }, { call }) {
      return yield call(queryAllRole, payload);
    },
  },
  reducers: {
    saveSonUserLists(state, { payload }) {
      const { pageNum, pageSize, total, dataInfos } = payload;
      const pagination = {
        currentPage: pageNum,
        pageSize,
        total,
      };
      return { ...state, tabledData: dataInfos, pagination };
    },
  },
};
export default Model;

import { addVideoMaterial } from '../requests';

const Model = {
  namespace: 'articleConversion',
  state: {},
  effects: {
    *addVideoMaterial({ payload }, { call }) {
      return yield call(addVideoMaterial, payload);
    },
  },
  reducers: {},
};
export default Model;

import myRequest from '@/services/myRequest';

// 数据总揽
export async function queryTotalDateStats(params) {
  return myRequest('get', '/dataStats/v2/totalDateStats', params);
}

// 账号详情统计
export async function queryAccountInfoStats(params) {
  return myRequest('get', '/dataStats/v2/accountInfoStats', params);
}

// 趋势图
export async function queryWeekStats(params) {
  return myRequest('get', '/dataStats/v2/weekStats', params);
}

// 平台饼图
export async function queryPlatformStats(params) {
  return myRequest('get', '/dataStats/v2/platformStats', params);
}

// 账号饼图
export async function queryAccountStats(params) {
  return myRequest('get', '/dataStats/v2/accountStats', params);
}

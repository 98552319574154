import { history } from 'umi';
import { fakeAccountLogin } from '@/services/global';
import { setAuthority } from '@/utils/authority';
import { getPageQuery } from '@/utils/utils';

const Model = {
  namespace: 'login',
  state: {
    status: undefined,
  },
  effects: {
    *login({ payload }, { call, put }) {
      const response = yield call(fakeAccountLogin, payload);
      yield put({
        type: 'changeLoginStatus',
        payload: response,
      });
      // Login successfully
      if (response.code === 0) {
        const res = yield yield put({
          type: 'user/getUserConfig',
        });
        if (res.code === 0) {
          yield put({
            type: 'loginRouter',
          });
        }
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    *logout() {
      const { redirect } = getPageQuery(); // Note: There may be security issues, please note
      if (window.location.pathname !== '/user/login' && !redirect) {
        yield history.replace({
          pathname: '/user/login',
        });
        setAuthority(null);
        localStorage.setItem('userMessage', null);
        window.location.reload();
      }
    },

    loginRouter() {
      const urlParams = new URL(window.location.href);
      const params = getPageQuery();
      const baseUrl = '/lvluo-app';
      let { redirect } = params;
      if (redirect) {
        const redirectUrlParams = new URL(redirect);

        if (redirectUrlParams.origin === urlParams.origin) {
          redirect = redirect.substr(urlParams.origin.length);

          if (redirect.match(/^\/.*#/)) {
            redirect = redirect.substr(redirect.indexOf('#') + 1);
          }
          if (redirect.match(/\/lvluo-app/)) {
            redirect = redirect.substr(baseUrl.length);
          }
        } else {
          window.location.href = baseUrl;
        }
      }
      history.replace(redirect || '/');
    },
  },
  reducers: {
    changeLoginStatus(state, { payload }) {
      const { code, userName } = payload;
      return { ...state, code, userName };
    },
  },
};
export default Model;

import myRequest from '@/services/myRequest';

// 获取文章审核列表
export async function getContPoolManagementLists(params) {
  return myRequest('get', '/contPool/queryContPools', params);
}

// 新增内容池
export async function addContPool(params) {
  return myRequest('post', '/contPool/addContPool', params);
}

// 修改内容池
export async function editContPool(params) {
  return myRequest('post', '/contPool/editContPool', params);
}

export async function getSourceGroupList(params) {
  return myRequest('get', '/sourceGroup/querySourceGroupList', params);
}

import {
  queryAccountInfo,
  deletePlatformConfigById,
  checkPlatformToken,
  updatePlatformConfigById,
} from '../requests';

const Model = {
  namespace: 'platformManagement',
  state: {
    accountInfos: [],
  },
  effects: {
    *queryAccountInfo({ payload }, { call, put }) {
      const res = yield call(queryAccountInfo, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveAccountInfo',
          payload: res.data,
        });
      }
    },
    *deletePlatformConfigById({ payload }, { call }) {
      return yield call(deletePlatformConfigById, payload);
    },
    *updatePlatformConfigById({ payload }, { call }) {
      return yield call(updatePlatformConfigById, payload);
    },
    *checkPlatformToken({ payload }, { call }) {
      return yield call(checkPlatformToken, payload);
    },
  },
  reducers: {
    saveAccountInfo(state, { payload }) {
      return { ...state, accountInfos: payload };
    },
  },
};
export default Model;

import myRequest from '@/services/myRequest';

// 小尾巴列表数据
export async function infoMarkList(params) {
  return myRequest('get', '/infoMark/list', params);
}

// 添加小尾巴
export async function infoMarkCreate(params) {
  return myRequest('post', '/infoMark/create', params);
}

// 编辑小尾巴
export async function infoMarkUpdate(params) {
  return myRequest('post', '/infoMark/update', params);
}

// 小尾巴预览
export async function infoMarkSpamPreview(params) {
  return myRequest('post', '/infoMark/spamPreview', params);
}

import { getRssList, createRss, updateRss, getTemplateList } from '../requests';

const initPagination = {
  pageSize: 10,
  currentPage: 1,
  total: 0,
};

const Model = {
  namespace: 'rssManagement',
  state: {
    tabledData: [],
    pagination: initPagination,
    templateList: [],
  },
  effects: {
    *getRssList({ payload }, { call, put }) {
      const res = yield call(getRssList, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveRssList',
          payload: res.data,
        });
      }
    },
    *getTemplateList({ payload }, { call, put }) {
      const res = yield call(getTemplateList, payload);
      if (res && res.code === 0 && res.data) {
        const list = Object.keys(res.data).map((key) => {
          return {
            name: key,
            value: res.data[key],
          };
        });
        yield put({
          type: 'saveTemplateList',
          payload: list,
        });
      }
    },

    *createRss({ payload }, { call }) {
      return yield call(createRss, payload);
    },

    *updateRss({ payload }, { call }) {
      return yield call(updateRss, payload);
    },
  },

  reducers: {
    saveRssList(state, { payload }) {
      const { pageNum, pageSize, total, dataInfos } = payload;
      const pagination = {
        currentPage: pageNum,
        pageSize,
        total,
      };
      return { ...state, tabledData: dataInfos, pagination };
    },
    saveTemplateList(state, { payload }) {
      return { ...state, templateList: payload };
    },
  },
};
export default Model;

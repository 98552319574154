import myRequest from '@/services/myRequest';

// 列表
export async function queryList(params) {
  return myRequest('get', '/chatMessage/queryList', params);
}

// 列表
export async function queryChatMessageListById(params) {
  return myRequest('get', '/chatMessage/queryChatMessageListById', params);
}

// 更新状态
export async function batchUpdateStatus(params) {
  return myRequest('post', '/chatMessage/batchUpdateStatus', params);
}

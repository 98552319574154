import {
  addSensitiveWord,
  editSensitiveWord,
  deleteSensitiveWord,
  querySensitiveWordById,
  querySensitiveWordList,
  addSensitiveType,
  getSensitiveType,
} from '../requests';

const initPagination = {
  pageSize: 10,
  currentPage: 1,
  total: 0,
};

const Model = {
  namespace: 'sensitiveWords',
  state: {
    tabledData: [],
    pagination: initPagination,
    sensitiveType: [],
  },
  effects: {
    *addSensitiveWord({ payload }, { call }) {
      return yield call(addSensitiveWord, payload);
    },
    *editSensitiveWord({ payload }, { call }) {
      return yield call(editSensitiveWord, payload);
    },
    *deleteSensitiveWord({ payload }, { call }) {
      return yield call(deleteSensitiveWord, payload);
    },
    *addSensitiveType({ payload }, { call }) {
      return yield call(addSensitiveType, payload);
    },
    *getSensitiveType({ payload }, { call, put }) {
      const res = yield call(getSensitiveType, payload);
      if (res && res.code === 0) {
        yield put({ type: 'saveSensitiveType', payload: res.data });
      }
    },
    *querySensitiveWordList({ payload }, { call, put }) {
      const res = yield call(querySensitiveWordList, payload);
      if (res && res.code === 0) {
        yield put({ type: 'saveSensitiveWordList', payload: res.data });
      }
    },
    *querySensitiveWordById({ payload }, { call }) {
      return yield call(querySensitiveWordById, payload);
    },
  },
  reducers: {
    saveSensitiveWordList(state, { payload }) {
      const { pageNum, pageSize, total, dataInfos } = payload;
      const pagination = {
        currentPage: pageNum,
        pageSize,
        total,
      };
      return { ...state, tabledData: dataInfos, pagination };
    },
    saveSensitiveType(state, { payload }) {
      return { ...state, sensitiveType: payload };
    },
  },
};
export default Model;

import myRequest from '@/services/myRequest';

// 获取推送列表
export async function getPushList(params) {
  return myRequest('get', '/push/list', params);
}

// 新增推送
export async function createPush(params) {
  return myRequest('post', '/push/rssPush', params);
}

// 获取所有rss
export async function queryRssInfo() {
  return myRequest('get', '/rss/queryRssInfo');
}

// 获取文章审核详情
export async function queryAuditContentById(params) {
  return myRequest('get', '/auditContent/queryAuditContentById', params);
}

// 是否可以被推送
export async function isPushable(params) {
  return myRequest('get', '/push/isPushable', params);
}

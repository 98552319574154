import myRequest from '@/services/myRequest';

// 添加来源组
export async function addSourceGroup(params) {
  return myRequest('post', '/sourceGroup/add', params, { requestType: 'json' });
}

// 添加来源组
export async function updateSourceGroup(params) {
  return myRequest('post', '/sourceGroup/update', params, { requestType: 'json' });
}

// 来源组列表数据
export async function querySourceGroupList(params) {
  return myRequest('get', '/sourceGroup/queryList', params);
}

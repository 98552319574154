import {
  queryReporterAndEditor,
  getCategoryList,
  getContPoolList,
  getChannelInfos,
  queryMaterialByInfoId,
  queryContentByInfoId,
  getPosition,
  queryMaterialEdit,
  queryPlatformList,
} from '@/services/global';
import { getAuthority } from '@/utils/authority';
import { getPageQuery } from '@/utils/utils';
import { stringify } from 'querystring';

const GlobalModel = {
  namespace: 'global',
  state: {
    collapsed: false,
    height: window.innerHeight,
    width: window.innerWidth,
    editors: [],
    reporters: [],
    categoryList: [],
    publishTopics: [],
    channelInfos: [],
    positions: [],
    allContPoolList: [],
    platformList: [],
  },
  effects: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    *getWindowSize({ payload }, { call, put }) {
      yield put({
        type: 'saveWindowSize',
        payload,
      });
    },

    *queryEditor(_, { call, put }) {
      const response = yield call(queryReporterAndEditor, { type: 0 });
      yield put({
        type: 'saveEditor',
        payload: response.data,
      });
    },

    *queryReporter(_, { call, put }) {
      const response = yield call(queryReporterAndEditor, { type: 1 });
      yield put({
        type: 'saveReporter',
        payload: response.data,
      });
    },

    *getCategoryList(_, { call, put, select }) {
      const curCategory = yield select((state) => state.global.categoryList);
      if (curCategory && curCategory.length) return;
      const response = yield call(getCategoryList);
      if (response.code === 0) {
        yield put({
          type: 'saveCategoryList',
          payload: response.data,
        });
      }
    },

    *getContPoolList({ payload }, { call }) {
      return yield call(getContPoolList, payload);
    },

    *getAllContPoolList({ payload }, { call, put }) {
      const res = yield call(getContPoolList, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveAllContPoolList',
          payload: res.data,
        });
      }
      return res;
    },

    *getChannelInfos(_, { call, put }) {
      const response = yield call(getChannelInfos);
      if (response && response.code === 0) {
        yield put({
          type: 'saveChannelInfos',
          payload: response.data,
        });
      }
    },

    *queryMaterialByInfoId({ payload }, { call }) {
      return yield call(queryMaterialByInfoId, payload);
    },

    *queryContentByInfoId({ payload }, { call }) {
      return yield call(queryContentByInfoId, payload);
    },

    *getPosition({ payload }, { call, put, select }) {
      const curPos = yield select((state) => state.global.positions);
      if (curPos && curPos.length) return;
      const response = yield call(getPosition, payload);
      if (response && response.code === 0) {
        yield put({
          type: 'savePosition',
          payload: response.data,
        });
      }
    },

    *queryMaterialEdit({ payload }, { call }) {
      return yield call(queryMaterialEdit, payload);
    },

    *queryPlatformList({ payload }, { call, put }) {
      const res = yield call(queryPlatformList, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'savePlatformList',
          payload: res.data,
        });
      }
    },
  },
  reducers: {
    changeLayoutCollapsed(
      state = {
        notices: [],
        collapsed: true,
      },
      { payload },
    ) {
      return { ...state, collapsed: payload };
    },

    saveWindowSize(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    saveEditor(state, { payload }) {
      return { ...state, editors: payload };
    },

    saveReporter(state, { payload }) {
      return { ...state, reporters: payload };
    },

    savePosition(state, { payload }) {
      return { ...state, positions: payload };
    },

    saveCategoryList(state, { payload }) {
      return {
        ...state,
        categoryList: payload,
      };
    },

    saveChannelInfos(state, { payload }) {
      return {
        ...state,
        channelInfos: payload,
      };
    },

    saveAllContPoolList(state, { payload }) {
      return {
        ...state,
        allContPoolList: payload,
      };
    },
    savePlatformList(state, { payload }) {
      return { ...state, platformList: payload };
    },
  },
  subscriptions: {
    setup({ history, dispatch }) {
      // Subscribe history(url) change, trigger `load` action if pathname is `/`
      history.listen(({ pathname, search }) => {
        if (pathname !== '/user/login' && pathname !== '/' && pathname !== '/article/h5Preview' && pathname !== '/public/selfContent' && pathname !== '/public/selfContentDetail') {
          dispatch({
            type: 'user/getUserConfig',
          });
          const authority = getAuthority();
          const { redirect } = getPageQuery(); // Note: There may be security issues, please note
          if (!authority) {
            if (window.location.pathname !== '/user/login' && !redirect) {
              history.replace({
                pathname: '/user/login',
                search: stringify({
                  redirect: window.location.href,
                }),
              });
            }
            return;
          }
        }
        if (typeof window.ga !== 'undefined') {
          window.ga('send', 'pageview', pathname + search);
        }
      });
    },
  },
};
export default GlobalModel;

import { getYesterdaySum, getLineChartSum } from '../requests';

const Model = {
  namespace: 'dashBoard',
  state: {
    yesterdaySum: {
      articleNum: 0,
      readNum: 0,
      videoNum: 0,
    },
    lineChartData: [],
  },
  effects: {
    *getYesterdaySum({ payload }, { call, put }) {
      const res = yield call(getYesterdaySum, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveYesterdaySum',
          payload: res.data,
        });
      }
    },
    *getLineChartSum({ payload }, { call, put }) {
      const res = yield call(getLineChartSum, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveLineChartSum',
          payload: res.data,
        });
      }
    },
  },
  reducers: {
    saveYesterdaySum(state, { payload }) {
      const { yesterdaySum } = state;
      return { ...state, yesterdaySum: { ...yesterdaySum, ...payload } };
    },
    saveLineChartSum(state, { payload }) {
      return { ...state, lineChartData: payload };
    },
  },
};
export default Model;

import { queryContentByKeyWords } from '../requests';

const Model = {
  namespace: 'titleCollege',
  state: {},
  effects: {
    *queryContentByKeyWords({ payload }, { call }) {
      return yield call(queryContentByKeyWords, payload);
    },
  },
};
export default Model;

import {
  getMaterialLists,
  updateMaterialList,
  queryByOriInfoId,
  createArticleAndVideo,
} from '../requests';

const initPagination = {
  pageSize: 10,
  currentPage: 1,
  total: 0,
};

const Model = {
  namespace: 'material',
  state: {
    materialLists: [],
    pagination: initPagination,
  },
  effects: {
    *getMaterialLists({ payload }, { call, put }) {
      const res = yield call(getMaterialLists, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveMaterialLists',
          payload: res.data,
        });
      }
    },
    *updateMaterialList({ payload }, { call }) {
      return yield call(updateMaterialList, payload);
    },
    *queryByOriInfoId({ payload }, { call }) {
      return yield call(queryByOriInfoId, payload);
    },
    *createArticleAndVideo({ payload }, { call }) {
      return yield call(createArticleAndVideo, payload);
    },
  },
  reducers: {
    saveMaterialLists(state, { payload }) {
      if (!payload) return { ...state, materialLists: [], pagination: initPagination };
      const { dataInfos, pageNum, pageSize, total } = payload;
      const pagination = { currentPage: pageNum, pageSize, total };
      return { ...state, materialLists: dataInfos, pagination };
    },
  },
};
export default Model;

import myRequest from '@/services/myRequest';

// 获取文章审核列表
export async function getAuditData(params) {
  return myRequest('get', '/audit/queryContPoolAudit', params);
}

// 获取文章审核详情
export async function queryAuditContentById(params) {
  return myRequest('get', '/auditContent/queryAuditContentById', params);
}

// 审核文章
export async function auditHuman(params) {
  return myRequest('post', '/audit/auditHuman', params);
}

// 审核文章
export async function auditContPoolBatchAudit(params) {
  return myRequest('post', '/audit/contPoolBatchAudit', params);
}

// 编辑文章
export async function updateField(params) {
  return myRequest('post', '/audit/updateField', params);
}

// 禁用媒体
export async function disableMediaStatus(params) {
  return myRequest('post', '/media/disable', params);
}

// 视频去logo
export async function videoDelogo(params) {
  return myRequest('post', '/auditContent/videoDelogo', params, { requestType: 'json' });
}

// 恢复原视频
export async function videoResume(params) {
  return myRequest('post', '/auditContent/videoResume', params, { requestType: 'json' });
}

// 上传本地视频
export async function uploadLocalVideo(params) {
  return myRequest('post', '/auditContent/uploadLocalVideo', params, { requestType: 'json' });
}

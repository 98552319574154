import myRequest from '@/services/myRequest';

// 添加敏感词
export async function addSensitiveWord(params) {
  return myRequest('post', '/sensitiveWord/add', params, { requestType: 'json' });
}

// 编辑敏感词
export async function editSensitiveWord(params) {
  return myRequest('post', '/sensitiveWord/edit', params, { requestType: 'json' });
}

// 删除敏感词
export async function deleteSensitiveWord(params) {
  return myRequest('post', '/sensitiveWord/delete', params);
}

// 新增敏感词类型
export async function addSensitiveType(params) {
  return myRequest('post', '/sensitiveWordType/add', params, { requestType: 'json' });
}

// 获取敏感词类型
export async function getSensitiveType(params) {
  return myRequest('get', '/sensitiveWordType/listAll', params);
}

// 敏感词列表
export async function querySensitiveWordList(params) {
  return myRequest('get', '/sensitiveWord/queryList', params);
}

// 通过id查询敏感词
export async function querySensitiveWordById(params) {
  return myRequest('get', '/sensitiveWord/queryById', params);
}

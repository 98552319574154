import myRequest from '@/services/myRequest';

export async function getMaterialLists(params) {
  return myRequest('get', '/material/queryMaterialList', params);
}

// 修改素材状态
export async function updateMaterialList(params) {
  return myRequest('post', '/material/disCardMaterial', params);
}

// 根据素材ID查询素材编辑后的文章
export async function queryByOriInfoId(params) {
  return myRequest('get', '/material/queryByOriInfoId', params);
}

// 新建文章和视频
export async function createArticleAndVideo(params) {
  return myRequest('post', '/distribute/createContent', params, { requestType: 'json' });
}

import myRequest from './myRequest';

// 用户登录
export async function fakeAccountLogin(params) {
  return myRequest('post', '/user/loginUser', params);
}

// 忘记密码
export async function forgetPassword(params) {
  return myRequest('post', '/user/updateUserPassword', params);
}

// 获取所有编辑、记者
export async function queryReporterAndEditor(params) {
  return myRequest('get', '/common/queryWorkers', params);
}

// 获取上传文件所需token
export async function getNosToken(params) {
  return myRequest('get', '/upload/nosToken', params);
}

// 获取用户信息
export async function getUserConfig(params) {
  return myRequest('get', '/user/getUserConfig', params);
}

// 获取分类数据
export async function getCategoryList() {
  return myRequest('get', '/common/categoryList');
}

// 获取内容池数据
/**
 * @params
 * status 状态 1 有效 -1 无效
 * type 1 审核池 2 素材池
 * itemType 文章类型 article 文章 svideo 视频
 * check 是否人审 1 是 0 否
 *
 * * */
export async function getContPoolList(params) {
  return myRequest('get', '/common/queryPublicContPools', params);
}

// 获取渠道数据
export async function getChannelInfos(params) {
  return myRequest('get', '/follow/channelInfos', params);
}

// 获取素热搜材详情数据
export async function queryContentByInfoId(params) {
  return myRequest('get', '/common/queryContentByInfoId', params);
}

// 获取渠道数据
export async function queryMaterialByInfoId(params) {
  return myRequest('get', '/material/queryMaterialByInfoId', params);
}

// 获取地区数据
export async function getPosition(params) {
  return myRequest('get', '/common/positions', params);
}

// 获取通过素材发布的文章
export async function queryMaterialEdit(params) {
  return myRequest('get', '/content/queryMaterialEdit', params);
}

// 查询所有平台
export async function queryPlatformList(params) {
  return myRequest('get', '/platform/queryPlatformList', params);
}

// 获取文件上传地址
export async function getUploadInfo(params) {
  return myRequest('get', '/upload/preSign', params);
}

// 获取视频封面截图
export async function getImageProduceCovers(params) {
  return myRequest('post', '/img/produceCovers', params);
}

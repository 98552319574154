import {
  queryPlatformList,
  addAccountInfo,
  queryAccountInfo,
  updateAccountInfoById,
} from '../requests';

const Model = {
  namespace: 'accountCenter',
  state: {
    platformList: [],
    accountInfos: [],
  },
  effects: {
    *queryPlatformList({ payload }, { call, put }) {
      const res = yield call(queryPlatformList, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'savePlatformList',
          payload: res.data,
        });
      }
    },
    *addAccountInfo({ payload }, { call }) {
      return yield call(addAccountInfo, payload);
    },
    *updateAccountInfoById({ payload }, { call }) {
      return yield call(updateAccountInfoById, payload);
    },
    *queryAccountInfo({ payload }, { call, put }) {
      const res = yield call(queryAccountInfo, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveAccountInfo',
          payload: res.data,
        });
      }
    },
  },
  reducers: {
    savePlatformList(state, { payload }) {
      return { ...state, platformList: payload };
    },
    saveAccountInfo(state, { payload }) {
      return { ...state, accountInfos: payload };
    },
  },
};
export default Model;

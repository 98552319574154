import { queryRssInfo, queryRssOutputInfo, queryRssOutputList } from '../requests';

const initPagination = {
  pageSize: 10,
  currentPage: 1,
  total: 0,
};

const Model = {
  namespace: 'outboundStatistics',
  state: {
    lineChartData: [],
    rssInfo: [],
    rssOutputInfo: [],
    rssOutputList: [],
    pagination: initPagination,
  },
  effects: {
    *queryRssInfo({ payload }, { call, put }) {
      const res = yield call(queryRssInfo, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveRssInfo',
          payload: res.data,
        });
      }
    },
    *queryRssOutputInfo({ payload }, { call, put }) {
      const res = yield call(queryRssOutputInfo, payload);
      if (res && res.code === 0) {
        if (res.data) {
          let addedData = {};
          res.data.forEach((item) => {
            // eslint-disable-next-line no-param-reassign
            item.time = item.time.substr(0, 5);
            if (!addedData[item.day]) {
              addedData[item.day] = [item];
            } else {
              const { length } = addedData[item.day];
              addedData[item.day].push({
                ...item,
                value: addedData[item.day][length - 1].value + item.value,
              });
            }
          });
          addedData = [].concat(...Object.keys(addedData).map((key) => addedData[key]));
          yield put({
            type: 'saveRssOutputInfo',
            payload: addedData,
          });
        }
      }
    },
    *queryRssOutputList({ payload }, { call, put }) {
      const res = yield call(queryRssOutputList, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveRssOutputList',
          payload: res.data,
        });
      } else {
        yield put({
          type: 'saveRssOutputList',
          payload: [],
        });
      }
    },
  },
  reducers: {
    saveRssInfo(state, { payload }) {
      return { ...state, rssInfo: payload };
    },
    saveRssOutputInfo(state, { payload }) {
      return { ...state, rssOutputInfo: payload };
    },
    saveRssOutputList(state, { payload }) {
      const { page, size, total, data } = payload;
      const pagination = {
        currentPage: page,
        pageSize: size,
        total,
      };
      return { ...state, rssOutputList: data, pagination };
    },
  },
};
export default Model;

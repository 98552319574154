import myRequest from '@/services/myRequest';

export async function queryList(params) {
  return myRequest('get', '/correctWords/queryList', params);
}

export async function add(params) {
  return myRequest('post', '/correctWords/add', params, { requestType: 'json' });
}

export async function update(params) {
  return myRequest('post', '/correctWords/update', params, { requestType: 'json' });
}

import myRequest from '@/services/myRequest';

// 查询平台账号
export async function queryAccountInfo(params) {
  return myRequest('get', '/platform/queryAccountInfo', params);
}

// 删除平台账号
export async function deletePlatformConfigById(params) {
  return myRequest('post', '/platform/deletePlatformConfigById', params);
}

// 更新平台账号状态
export async function updatePlatformConfigById(params) {
  return myRequest('post', '/platform/updatePlatformConfigById', params, {
    requestType: 'json',
  });
}

// 检测平台有效性
export async function checkPlatformToken() {
  return myRequest('get', '/platform/checkPlatformToken');
}

import myRequest from '@/services/myRequest';

// 获取审核统计数据
export async function queryTotalInfos(params) {
  return myRequest('get', '/user/overAll', params);
}

export async function queryUserOverDate(params) {
  return myRequest('get', '/user/overDate', params);
}

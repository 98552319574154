import myRequest from '@/services/myRequest';

// 获取记者编辑列表
export async function queryWorkerList(params) {
  return myRequest('get', '/worker/queryWorkerList', params);
}

// 新增记者
export async function addWorker(params) {
  return myRequest('post', '/worker/addWorker', params);
}

// 编辑记者
export async function editWorker(params) {
  return myRequest('post', '/worker/editWorker', params);
}

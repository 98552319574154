import { queryAccountInfoStats } from '../requests';
import { queryAccountInfo } from '@/pages/accountCenter/requests';

const Model = {
  namespace: 'platformData',
  state: {
    histogramData: [],
    lineChartData: [],
    accountInfos: [],
  },
  effects: {
    *getHistogramData({ payload }, { call, put }) {
      const res = yield call(queryAccountInfoStats, payload);
      if (res && res.code === 0) {
        const data = (res.data || []).map((item) => {
          const numbered = { ...item, ...item.accountDataInfos[0] };
          numbered.readNum = +numbered.readNum || 1111.1111;
          numbered.playNum = +numbered.playNum || 1.1111111;
          numbered.articleNum = +numbered.articleNum || 1.1111111;
          numbered.svideoNum = +numbered.svideoNum || 1.1111111;
          numbered.fanNum = +numbered.fanNum || 11.1111111;
          numbered.profitNum = +numbered.profitNum || 1.1111111;
          numbered.platformName = `${numbered.showName}-${numbered.accountName}`;
          return numbered;
        });
        yield put({
          type: 'saveHistogramData',
          payload: data,
        });
      }
    },
    *getLineChartData({ payload }, { call, put }) {
      const res = yield call(queryAccountInfoStats, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveLineChartData',
          payload: res.data,
        });
      }
      return res;
    },
    *queryAccountInfo({ payload }, { call, put }) {
      const res = yield call(queryAccountInfo, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveAccountInfo',
          payload: res.data,
        });
      }
    },
  },
  reducers: {
    saveHistogramData(state, { payload }) {
      return { ...state, histogramData: payload };
    },
    saveLineChartData(state, { payload }) {
      return { ...state, lineChartData: payload };
    },
    saveAccountInfo(state, { payload }) {
      return { ...state, accountInfos: payload };
    },
  },
};
export default Model;

import { getPushExpressList, queryHotContent } from '../requests';

const initPagination = {
  pageSize: 10,
  currentPage: 1,
  total: 0,
};

const Model = {
  namespace: 'pushExpress',
  state: {
    tabledData: [],
    pagination: initPagination,
  },
  effects: {
    *getPushExpressList({ payload }, { call, put }) {
      const res = yield call(getPushExpressList, payload);
      if (res && res.code === 0 && res.data) {
        yield put({
          type: 'savePushExpressList',
          payload: res.data,
        });
      }
    },
    *queryHotContent({ payload }, { call, put }) {
      const res = yield call(queryHotContent, { title: payload.title, itemType: payload.itemType });
      if (res && res.code === 0) {
        yield put({
          type: 'setRelatedLists',
          payload: {
            data: res.data,
            rankIndex: payload.rankIndex,
          },
        });
      }
    },
  },
  reducers: {
    savePushExpressList(state, { payload }) {
      return { ...state, tabledData: payload };
    },
    setRelatedLists(state, { payload }) {
      const { tabledData } = state;
      const index = tabledData.findIndex((item) => item.infoId === payload.rankIndex);
      tabledData[index].relatedInfos = payload.data;
      return { ...state, tabledData };
    },
    setSameContent(state, { payload }) {
      const { tabledData } = state;
      const index = tabledData.findIndex((item) => item.infoId === payload.rankIndex);
      tabledData[index].relatedInfos = tabledData[index].similarArticle;
      return { ...state, tabledData };
    },
  },
};
export default Model;

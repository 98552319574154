import { infoMarkList, infoMarkCreate, infoMarkSpamPreview, infoMarkUpdate } from '../requests';

const initPagination = {
  pageSize: 10,
  currentPage: 1,
  total: 0,
};

const Model = {
  namespace: 'infoMarkManagement',
  state: {
    tabledData: [],
    pagination: initPagination,
    sourceGroupList: [],
  },
  effects: {
    *infoMarkList({ payload }, { call, put }) {
      const res = yield call(infoMarkList, payload);
      if (res && res.code === 0) {
        yield put({ type: 'saveInfoMarkList', payload: res.data });
      }
    },
    *infoMarkCreate({ payload }, { call }) {
      return yield call(infoMarkCreate, payload);
    },
    *infoMarkUpdate({ payload }, { call }) {
      return yield call(infoMarkUpdate, payload);
    },
    *infoMarkSpamPreview({ payload }, { call }) {
      return yield call(infoMarkSpamPreview, payload);
    },
  },
  reducers: {
    saveInfoMarkList(state, { payload }) {
      const { currentPage, pageSize, total, infoData } = payload;
      const pagination = {
        currentPage,
        pageSize,
        total,
      };
      return { ...state, tabledData: infoData, pagination };
    },
  },
};
export default Model;

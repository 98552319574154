import myRequest from '@/services/myRequest';

// 来源列表数据
export async function querySourceList(params) {
  return myRequest('get', '/source/queryList', params);
}

// 添加来源
export async function addSource(params) {
  return myRequest('post', '/source/add', params, { requestType: 'json' });
}

// 更新来源
export async function updateSource(params) {
  return myRequest('post', '/source/update', params, { requestType: 'json' });
}

// 更新来源
export async function batchUpdateSource(params) {
  return myRequest('post', '/source/batchUpdate', params, { requestType: 'json' });
}

// 来源组列表
export async function querySourceGroupList() {
  return myRequest('get', '/sourceGroup/querySourceGroupList');
}

export async function getGroups(params) {
  return myRequest('get', '/source/getGroups', params);
}

export async function getStatistics(params) {
  return myRequest('get', '/source/getStatistics', params);
}

import myRequest from '@/services/myRequest';

// 获取rss列表
export async function getRssList(params) {
  return myRequest('get', '/rss/queryList', params);
}

// 新增Rss
export async function createRss(params) {
  return myRequest('post', '/rss/add', params, { requestType: 'json' });
}

// 编辑Rss
export async function updateRss(params) {
  return myRequest('post', '/rss/update', params, { requestType: 'json' });
}

// 删除Rss
export async function deleteRss(params) {
  return myRequest('post', '/rss/delete', params, { requestType: 'json' });
}

// 获取rss模板
export async function getTemplateList(params) {
  return myRequest('get', '/rss/getTemplates', params);
}

import myRequest from '@/services/myRequest';

// 拉黑列表
export async function getBlacklistInfoList(params) {
  return myRequest('get', '/blacklistInfo/queryList', params);
}

// 添加拉黑
export async function addBlacklistInfo(params) {
  return myRequest('post', '/blacklistInfo/add', params, { requestType: 'json' });
}

// 删除拉黑
export async function deleteBlacklistInfo(params) {
  return myRequest('post', '/blacklistInfo/delete', params, { requestType: 'json' });
}

// 修改拉黑
export async function updateBlacklistInfo(params) {
  return myRequest('post', '/blacklistInfo/update', params, { requestType: 'json' });
}

import { stringify } from 'qs';
import request from '@/utils/request';
import { history } from 'umi';
import { message } from 'antd';
import NProgress from 'nprogress'; // 引入nprogress插件

const urlConfig = {
  local: '/lvluo-app/api',
  // pro: isBuildForElectron ? 'http://192.168.232.169:8020/lvluo-app/api' : '/lvluo-app/api',
  // eslint-disable-next-line no-undef
  pro: BUILD_FOR_ELECTRON === 'true' ? 'https://lvluo.huiwen.co/lvluo-app/api' : '/lvluo-app/api',
  // eslint-disable-next-line no-undef
  // pro: BUILD_FOR_ELECTRON === 'true' ? 'http://feed.dev.huiwen.co/lvluo-app/api' : '/lvluo-app/api',
};

let extraHeader = {};
// eslint-disable-next-line no-undef
if (BUILD_FOR_ELECTRON === 'true') {
  extraHeader = {
    'Client-Type': 'PC',
  };
} else {
  extraHeader = {
    'Client-Type': 'WEB',
  };
}

// 区分开发环境和生产环境的 baseUrl
let apiPrefixes;
switch (process.env.NODE_ENV) {
  case 'development':
    apiPrefixes = urlConfig.local;
    break;
  case 'production':
    apiPrefixes = urlConfig.pro;
    break;
  default:
    apiPrefixes = urlConfig.local;
}

const checkStatus = (response) => {
  NProgress.done();
  if (!response || !response.data) {
    return null;
  }
  if (response.data.code !== 0) {
    if (response.data.code === 1005) {
      // if (getAuthority()) {
      //   message.error('登录失效，请重新登录');
      // } else {
      //   message.error('用户未登录');
      // }
      history.push('/user/login');
    } else if (response.data.code === 1009) {
      message.error(response.data.msg);
      history.push('/user/login');
    } else {
      message.error(response.data.msg);
    }
  }
  return response.data;
};

const dealObjectValue = (obj) => {
  let params;
  if (Object.prototype.toString.call(obj) === '[object Object]') {
    params = {};
    if (obj === null || obj === undefined || obj === '') return params;
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
        params[key] = obj[key];
      }
    });
  } else {
    params = obj;
  }
  return params;
};

const myRequest = (
  method,
  url,
  params,
  // requestType （json , form）
  extend = { requestType: 'form', header: {} },
) => {
  NProgress.start();
  const filterParams = dealObjectValue(params);
  let fullPath = apiPrefixes + url;
  // http 开头直接使用
  if (/^https?:\/\//.test(url)) {
    fullPath = url;
  }
  const parsedMethod = method.toLowerCase();

  if (parsedMethod === 'get') {
    if (filterParams) {
      return request(`${fullPath}?${stringify(filterParams)}`, {
        method: 'GET',
        headers: { ...extend.header, ...extraHeader },
      }).then(checkStatus);
    }
    return request(`${fullPath}`, {
      method: 'GET',
      headers: { ...extend.header, ...extraHeader },
    }).then(checkStatus);
  }

  if (parsedMethod === 'post') {
    return request(fullPath, {
      method: 'POST',
      requestType: extend.requestType,
      data: filterParams,
      headers: { ...extend.header, ...extraHeader },
    }).then(checkStatus);
  }
  return false;
};

export const downLoadRequest = (url, params) => {
  let fullPath = apiPrefixes + url;
  if (/^https?:\/\//.test(url)) {
    fullPath = url;
  }
  const filterParams = dealObjectValue(params);
  return request(`${fullPath}?${stringify(filterParams)}`, {
    method: 'GET',
    responseType: 'blob',
    headers: { ...extraHeader },
  });
};

export default myRequest;

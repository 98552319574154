import {
  createArticleAndVideo,
  queryContentByInfoId,
  getVideoInfo,
  queryMaterialByInfoId,
  editArticleAndVideo,
  getPreviewUrl,
  previewByInfoId,
  getImgUrlByFileUrl,
  checkContent,
  queryAccountInfo,
} from '../requests';

const Model = {
  namespace: 'article',
  state: {},
  effects: {
    *createArticle({ payload }, { call }) {
      return yield call(createArticleAndVideo, payload);
    },

    *editArticle({ payload }, { call }) {
      return yield call(editArticleAndVideo, payload);
    },

    *createVideo({ payload }, { call }) {
      return yield call(createArticleAndVideo, payload);
    },

    *editVideo({ payload }, { call }) {
      return yield call(editArticleAndVideo, payload);
    },
    //
    // *queryArticleByInfoId({ payload }, { call }) {
    //   // 获取素材
    //   if (payload.editorType === 'material') {
    //     return yield call(queryMaterialByInfoId, payload);
    //   }
    //   // 获取自建
    //   if (payload.editorType === 'selfArticle' || payload.editorType === 'hotSearch') {
    //     return yield call(queryContentByInfoId, payload);
    //   }
    //   return {};
    // },

    *queryMaterialByInfoId({ payload }, { call }) {
      return yield call(queryMaterialByInfoId, payload);
    },

    *checkContent({ payload }, { call }) {
      return yield call(checkContent, payload);
    },

    *queryContentByInfoId({ payload }, { call }) {
      return yield call(queryContentByInfoId, payload);
    },

    *queryVideoByInfoId({ payload }, { call }) {
      return yield call(queryContentByInfoId, payload);
    },

    *getVideoInfo({ payload }, { call }) {
      return yield call(getVideoInfo, payload);
    },

    *getPreviewUrl({ payload }, { call }) {
      return yield call(getPreviewUrl, payload);
    },

    *previewByInfoId({ payload }, { call }) {
      return yield call(previewByInfoId, payload);
    },

    *getImgUrlByFileUrl({ payload }, { call }) {
      return yield call(getImgUrlByFileUrl, payload);
    },

    *queryAccountInfo({ payload }, { call }) {
      return yield call(queryAccountInfo, payload);
    },
  },
  reducers: {},
};
export default Model;

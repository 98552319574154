import { addSourceGroup, querySourceGroupList, updateSourceGroup } from '../requests';

const initPagination = {
  pageSize: 10,
  currentPage: 1,
  total: 0,
};

const Model = {
  namespace: 'sourceGroupManagement',
  state: {
    tabledData: [],
    pagination: initPagination,
  },
  effects: {
    *querySourceGroupList({ payload }, { call, put }) {
      const res = yield call(querySourceGroupList, payload);
      if (res && res.code === 0) {
        yield put({ type: 'saveSourceGroupList', payload: res.data });
      }
    },
    *addSourceGroup({ payload }, { call }) {
      return yield call(addSourceGroup, payload);
    },
    *updateSourceGroup({ payload }, { call }) {
      return yield call(updateSourceGroup, payload);
    },
  },
  reducers: {
    saveSourceGroupList(state, { payload }) {
      const { pageNum, pageSize, total, dataInfos } = payload;
      const pagination = {
        currentPage: pageNum,
        pageSize,
        total,
      };
      return { ...state, tabledData: dataInfos, pagination };
    },
  },
};
export default Model;

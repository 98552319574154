import { forgetPassword, getUserConfig } from '@/services/global';
import { setAuthority } from '@/utils/authority';

const UserModel = {
  namespace: 'user',
  state: {
    companyInfo: {},
    userInfo: {},
    permission: [],
    accountGroup: [],
    contPoolGroup: [],
  },
  effects: {
    *forgetPassword({ payload }, { call }) {
      return yield call(forgetPassword, payload);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    *getUserConfig(_, { call, put }) {
      const response = yield call(getUserConfig);
      if (response.code === 0 && response.data) {
        const { userInfo, permissions, companyInfo, contPoolGroup, accountGroup } = response.data;
        const authority = permissions && permissions.length ? permissions : [];
        setAuthority(authority);
        yield put({
          type: 'saveCompanyInfo',
          payload: companyInfo,
        });
        yield put({
          type: 'saveUserInfo',
          payload: userInfo,
        });
        yield put({
          type: 'savePermissions',
          payload: permissions,
        });
        yield put({
          type: 'saveAccountGroup',
          payload: accountGroup,
        });
        yield put({
          type: 'saveContPoolGroup',
          payload: contPoolGroup,
        });
      }
      return response;
    },
  },
  reducers: {
    saveCompanyInfo(state, { payload }) {
      return { ...state, companyInfo: payload };
    },
    saveUserInfo(state, { payload }) {
      return { ...state, userInfo: payload };
    },
    savePermissions(state, { payload }) {
      return { ...state, permission: payload };
    },
    saveAccountGroup(state, { payload }) {
      return { ...state, accountGroup: payload || [] };
    },
    saveContPoolGroup(state, { payload }) {
      return { ...state, contPoolGroup: payload || [] };
    },
  },
};
export default UserModel;

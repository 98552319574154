import { emailNoticeConfirm, queryEmailNoticeList } from '../requests';

const initPagination = {
  pageSize: 10,
  currentPage: 1,
  total: 100,
};

const Model = {
  namespace: 'emailMessage',
  state: {
    tabledData: [],
    pagination: initPagination,
  },
  effects: {
    *queryEmailNoticeList({ payload }, { call, put }) {
      const res = yield call(queryEmailNoticeList, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveEmailNoticeList',
          payload: res.data,
        });
      }
    },
    *emailNoticeConfirm({ payload }, { call }) {
      const res = yield call(emailNoticeConfirm, payload);
      return res;
    },
  },
  reducers: {
    saveEmailNoticeList(state, { payload }) {
      return { ...state, tabledData: payload };
    },
  },
};
export default Model;

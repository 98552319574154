import { queryList, queryChatMessageListById, batchUpdateStatus } from '../requests';

const initPagination = {
  pageSize: 10,
  currentPage: 1,
  total: 0,
};

const Model = {
  namespace: 'messageInfo',
  state: {
    tabledData: [],
    pagination: initPagination,
  },
  effects: {
    *queryList({ payload }, { call, put }) {
      const res = yield call(queryList, payload);
      if (res && res.code === 0) {
        yield put({ type: 'saveList', payload: res.data });
      }
    },
    *queryChatMessageListById({ payload }, { call }) {
      return yield call(queryChatMessageListById, payload);
    },
    *batchUpdateStatus({ payload }, { call }) {
      return yield call(batchUpdateStatus, payload);
    },
  },
  reducers: {
    saveList(state, { payload }) {
      return { ...state, tabledData: payload };
    },
  },
};
export default Model;

// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/app/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/article/h5Preview",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__article__h5Preview' */'/app/src/pages/article/h5Preview'), loading: LoadingComponent}),
    "name": "h5Preview",
    "exact": true
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__LayoutContent' */'/app/src/layouts/LayoutContent'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/public",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'/app/src/layouts/BlankLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/public/selfContent",
            "name": "创作管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__publicSelfContent' */'/app/src/pages/publicSelfContent'), loading: LoadingComponent}),
            "authority": "publicSelfContent",
            "exact": true
          },
          {
            "path": "/public/selfContentDetail",
            "name": "创作管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__publicSelfContent__components__Detail' */'/app/src/pages/publicSelfContent/components/Detail'), loading: LoadingComponent}),
            "authority": "publicSelfContent",
            "exact": true
          }
        ]
      },
      {
        "path": "/user",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'/app/src/layouts/UserLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "name": "登录",
            "path": "/user/login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__login' */'/app/src/pages/user/login'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "forgetPassword",
            "path": "/user/forgetPassword",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__forgetPassword' */'/app/src/pages/user/forgetPassword'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "successPage",
            "path": "/user/successPage",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__forgetPassword__SuccessPage' */'/app/src/pages/user/forgetPassword/SuccessPage'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'/app/src/layouts/SecurityLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/app/src/layouts/BasicLayout'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/",
                "redirect": "/lvluo",
                "exact": true
              },
              {
                "path": "/lvluo",
                "name": "创作中心",
                "icon": "HomeOutlined",
                "authority": [
                  "material",
                  "videoCreate",
                  "articleCreate",
                  "selfBuiltManagement",
                  "hotSearch",
                  "articleAudit",
                  "videoAudit",
                  "contentManagement",
                  "rssManagement",
                  "contPoolManagement",
                  "accountManagement",
                  "workerManagement",
                  "sensitiveWords",
                  "outboundStatistics",
                  "teamStatistics",
                  "channelStatistics",
                  "titleCollege",
                  "dataOverview"
                ],
                "routes": [
                  {
                    "path": "/lvluo",
                    "redirect": "/lvluo/home",
                    "exact": true
                  },
                  {
                    "path": "/lvluo/home",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home' */'/app/src/pages/home'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/lvluo/Statistics",
                    "name": "数据",
                    "icon": "LineChartOutlined",
                    "authority": [
                      "outboundStatistics",
                      "teamStatistics",
                      "channelStatistics",
                      "dataOverview",
                      "monitoring",
                      "platformData",
                      "messageInfo",
                      "emailMessage",
                      "readInfo"
                    ],
                    "routes": [
                      {
                        "path": "/lvluo/Statistics/dataOverview",
                        "name": "数据总览",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dataOverview' */'/app/src/pages/dataOverview'), loading: LoadingComponent}),
                        "authority": "dataOverview",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/Statistics/platformData",
                        "name": "平台统计",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__platformData' */'/app/src/pages/platformData'), loading: LoadingComponent}),
                        "authority": "platformData",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/Statistics/outboundStatistics",
                        "name": "出库数据",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__outboundStatistics' */'/app/src/pages/outboundStatistics'), loading: LoadingComponent}),
                        "authority": "outboundStatistics",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/Statistics/dashBoard",
                        "name": "入库数据",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dashBoard' */'/app/src/pages/dashBoard'), loading: LoadingComponent}),
                        "authority": "dashBoard",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/Statistics/teamStatistics",
                        "name": "团队数据",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__teamStatistics' */'/app/src/pages/teamStatistics'), loading: LoadingComponent}),
                        "authority": "teamStatistics",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/Statistics/userDateStatistics",
                        "name": "个人数据",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__teamStatistics__components__DetailData' */'/app/src/pages/teamStatistics/components/DetailData'), loading: LoadingComponent}),
                        "hideInMenu": true,
                        "authority": "teamStatistics",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/Statistics/channelStatistics",
                        "name": "渠道统计",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__channelStatistics' */'/app/src/pages/channelStatistics'), loading: LoadingComponent}),
                        "authority": "channelStatistics",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/Statistics/monitoring",
                        "name": "监控通告",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__monitoring' */'/app/src/pages/monitoring'), loading: LoadingComponent}),
                        "authority": "monitoring",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/Statistics/messageInfo",
                        "name": "留言信息",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__messageInfo' */'/app/src/pages/messageInfo'), loading: LoadingComponent}),
                        "authority": "messageInfo",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/Statistics/hotSearchManagement",
                        "name": "热搜管理",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__hotSearchManagement' */'/app/src/pages/hotSearchManagement'), loading: LoadingComponent}),
                        "authority": "hotSearchManagement",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/Statistics/hotSearchManagement/detail",
                        "name": "热搜详情",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__hotSearchManagement__components__Detail' */'/app/src/pages/hotSearchManagement/components/Detail'), loading: LoadingComponent}),
                        "authority": "hotSearchManagement",
                        "hideInMenu": true,
                        "exact": true
                      },
                      {
                        "path": "/lvluo/Statistics/commentManagement",
                        "name": "评论管理",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__commentManagement' */'/app/src/pages/commentManagement'), loading: LoadingComponent}),
                        "authority": "commentManagement",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/Statistics/commentManagement/AccountArticle",
                        "name": "评论文章",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__commentManagement__components__AccountArticle' */'/app/src/pages/commentManagement/components/AccountArticle'), loading: LoadingComponent}),
                        "authority": "commentManagement",
                        "hideInMenu": true,
                        "exact": true
                      },
                      {
                        "path": "/lvluo/Statistics/emailMessage",
                        "name": "邮箱信息",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__emailMessage' */'/app/src/pages/emailMessage'), loading: LoadingComponent}),
                        "authority": "emailMessage",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/Statistics/readInfo",
                        "name": "阅读数据",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__readInfo' */'/app/src/pages/readInfo'), loading: LoadingComponent}),
                        "authority": "readInfo",
                        "exact": true
                      }
                    ]
                  },
                  {
                    "path": "/lvluo/create",
                    "name": "创作",
                    "icon": "EditOutlined",
                    "authority": [
                      "hotSearch",
                      "material",
                      "titleCollege",
                      "articleCreate",
                      "videoCreate",
                      "selfBuiltManagement"
                    ],
                    "routes": [
                      {
                        "path": "/lvluo/create/hotSearch",
                        "name": "热搜榜",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__hotSearch' */'/app/src/pages/hotSearch'), loading: LoadingComponent}),
                        "authority": "hotSearch",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/create/monitorCategory",
                        "name": "监控分类",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__monitorCategory' */'/app/src/pages/monitorCategory'), loading: LoadingComponent}),
                        "hideInMenu": true,
                        "authority": "monitorPanel",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/create/monitorCategoryDetail",
                        "name": "监控面板统计",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__monitorCategory__components__Detail' */'/app/src/pages/monitorCategory/components/Detail'), loading: LoadingComponent}),
                        "hideInMenu": true,
                        "authority": "monitorPanel",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/create/monitorPanel",
                        "name": "监控面板",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__monitorPanel' */'/app/src/pages/monitorPanel'), loading: LoadingComponent}),
                        "authority": "monitorPanel",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/create/monitorPanelStatistics",
                        "name": "监控面板统计",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__monitorPanel__components__Statistics' */'/app/src/pages/monitorPanel/components/Statistics'), loading: LoadingComponent}),
                        "hideInMenu": true,
                        "authority": "monitorPanel",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/create/monitorSource",
                        "name": "信源统计",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__monitorSource' */'/app/src/pages/monitorSource'), loading: LoadingComponent}),
                        "hideInMenu": true,
                        "authority": "monitorPanel",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/create/monitorSourceDetail",
                        "name": "信源每日统计",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__monitorSource__components__Detail' */'/app/src/pages/monitorSource/components/Detail'), loading: LoadingComponent}),
                        "hideInMenu": true,
                        "authority": "monitorPanel",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/create/material",
                        "name": "素材库",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__material' */'/app/src/pages/material'), loading: LoadingComponent}),
                        "authority": "material",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/create/pushExpress",
                        "name": "push快讯",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__pushExpress' */'/app/src/pages/pushExpress'), loading: LoadingComponent}),
                        "authority": "pushExpress",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/create/articleCreate",
                        "name": "发布文章",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__article__articleCreate' */'/app/src/pages/article/articleCreate'), loading: LoadingComponent}),
                        "authority": "articleCreate",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/create/articleEdit",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__article__articleCreate' */'/app/src/pages/article/articleCreate'), loading: LoadingComponent}),
                        "hideInMenu": true,
                        "authority": "articleCreate",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/create/videoCreate",
                        "name": "发布视频",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__article__videoCreate' */'/app/src/pages/article/videoCreate'), loading: LoadingComponent}),
                        "authority": "videoCreate",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/create/videoEdit",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__article__videoCreate' */'/app/src/pages/article/videoCreate'), loading: LoadingComponent}),
                        "authority": "videoCreate",
                        "hideInMenu": true,
                        "exact": true
                      },
                      {
                        "path": "/lvluo/create/selfBuiltManagement",
                        "name": "创作管理",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__selfBuiltManagement' */'/app/src/pages/selfBuiltManagement'), loading: LoadingComponent}),
                        "authority": "selfBuiltManagement",
                        "exact": true
                      }
                    ]
                  },
                  {
                    "path": "/lvluo/audit",
                    "name": "审核",
                    "icon": "AuditOutlined",
                    "authority": [
                      "articleAudit",
                      "videoAudit",
                      "consultingAudit",
                      "articleAuditSecond",
                      "contentManagement",
                      "videoAuditSecond",
                      "auditRecords",
                      "theBlacklist"
                    ],
                    "routes": [
                      {
                        "path": "/lvluo/audit/articleAudit",
                        "name": "文章初审",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__audit' */'/app/src/pages/audit'), loading: LoadingComponent}),
                        "authority": "articleAudit",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/audit/videoAudit",
                        "name": "视频初审",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__audit' */'/app/src/pages/audit'), loading: LoadingComponent}),
                        "authority": "videoAudit",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/audit/consultingAudit",
                        "name": "短资讯审核",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__audit' */'/app/src/pages/audit'), loading: LoadingComponent}),
                        "authority": "consultingAudit",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/audit/articleAuditSecond",
                        "name": "文章二审",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__audit' */'/app/src/pages/audit'), loading: LoadingComponent}),
                        "authority": "articleAuditSecond",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/audit/videoAuditSecond",
                        "name": "视频二审",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__audit' */'/app/src/pages/audit'), loading: LoadingComponent}),
                        "authority": "videoAuditSecond",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/audit/contentManagement",
                        "name": "审核管理",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contentManagement' */'/app/src/pages/contentManagement'), loading: LoadingComponent}),
                        "authority": "contentManagement",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/audit/auditRecords",
                        "name": "人审记录",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__auditRecords' */'/app/src/pages/auditRecords'), loading: LoadingComponent}),
                        "authority": "auditRecords",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/audit/theBlacklist",
                        "name": "黑名单",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__theBlacklist' */'/app/src/pages/theBlacklist'), loading: LoadingComponent}),
                        "authority": "theBlacklist",
                        "exact": true
                      }
                    ]
                  },
                  {
                    "path": "/lvluo/management",
                    "name": "管理",
                    "icon": "ProfileOutlined",
                    "authority": [
                      "rssManagement",
                      "contPoolManagement",
                      "accountManagement",
                      "sensitiveWords",
                      "workerManagement",
                      "sensitiveWords",
                      "sourceManagement",
                      "sourceGroupManagement",
                      "mediaManagement",
                      "pushManagement",
                      "infoMarkManagement",
                      "wechatMsgManagement",
                      "accountGroupManagement",
                      "contentGroupManagement",
                      "roleManagement",
                      "operationLog",
                      "correctionManagement"
                    ],
                    "routes": [
                      {
                        "path": "/lvluo/management/rssManagement",
                        "name": "RSS管理",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__rssManagement' */'/app/src/pages/rssManagement'), loading: LoadingComponent}),
                        "authority": "rssManagement",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/management/contPoolManagement",
                        "name": "内容池管理",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contPoolManagement' */'/app/src/pages/contPoolManagement'), loading: LoadingComponent}),
                        "authority": "contPoolManagement",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/management/accountManagement",
                        "name": "子账号管理",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accountManagement' */'/app/src/pages/accountManagement'), loading: LoadingComponent}),
                        "authority": "accountManagement",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/management/roleManagement",
                        "name": "角色管理",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__roleManagement' */'/app/src/pages/roleManagement'), loading: LoadingComponent}),
                        "authority": "roleManagement",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/management/sensitiveWords",
                        "name": "敏感词管理",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sensitiveWords' */'/app/src/pages/sensitiveWords'), loading: LoadingComponent}),
                        "authority": "sensitiveWords",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/management/correctionManagement",
                        "name": "纠错管理",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__correctionManagement' */'/app/src/pages/correctionManagement'), loading: LoadingComponent}),
                        "authority": "correctionManagement",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/management/workerManagement",
                        "name": "记者/编辑管理",
                        "icon": "team",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workerManagement' */'/app/src/pages/workerManagement'), loading: LoadingComponent}),
                        "authority": "workerManagement",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/management/sourceManagement",
                        "name": "来源管理",
                        "icon": "team",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sourceManagement' */'/app/src/pages/sourceManagement'), loading: LoadingComponent}),
                        "authority": "sourceManagement",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/management/sourceStatistics",
                        "name": "源数据统计",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sourceManagement__components__Statistics' */'/app/src/pages/sourceManagement/components/Statistics'), loading: LoadingComponent}),
                        "hideInMenu": true,
                        "authority": "sourceManagement",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/management/sourceGroupManagement",
                        "name": "来源组管理",
                        "icon": "team",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sourceGroupManagement' */'/app/src/pages/sourceGroupManagement'), loading: LoadingComponent}),
                        "authority": "sourceGroupManagement",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/management/mediaManagement",
                        "name": "媒体管理",
                        "icon": "team",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__mediaManagement' */'/app/src/pages/mediaManagement'), loading: LoadingComponent}),
                        "authority": "mediaManagement",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/management/pushManagement",
                        "name": "推送管理",
                        "icon": "team",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__pushManagement' */'/app/src/pages/pushManagement'), loading: LoadingComponent}),
                        "authority": "pushManagement",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/management/infoMarkManagement",
                        "name": "小尾巴列表",
                        "icon": "team",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__infoMarkManagement' */'/app/src/pages/infoMarkManagement'), loading: LoadingComponent}),
                        "authority": "infoMarkManagement",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/management/wechatMsgManagement",
                        "name": "微信消息回复",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wechatMsgManagement' */'/app/src/pages/wechatMsgManagement'), loading: LoadingComponent}),
                        "authority": "wechatMsgManagement",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/management/wechatMsgReceive",
                        "name": "微信消息回复统计",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wechatMsgManagement__components__ReceiveList' */'/app/src/pages/wechatMsgManagement/components/ReceiveList'), loading: LoadingComponent}),
                        "hideInMenu": true,
                        "authority": "wechatMsgManagement",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/management/accountGroupManagement",
                        "name": "账号组管理",
                        "icon": "team",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accountGroupManagement' */'/app/src/pages/accountGroupManagement'), loading: LoadingComponent}),
                        "authority": "accountGroupManagement",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/management/contentGroupManagement",
                        "name": "内容组管理",
                        "icon": "team",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contentGroupManagement' */'/app/src/pages/contentGroupManagement'), loading: LoadingComponent}),
                        "authority": "contentGroupManagement",
                        "exact": true
                      },
                      {
                        "path": "/lvluo/management/operationLog",
                        "name": "操作日志",
                        "icon": "DiffOutlined",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operationLog' */'/app/src/pages/operationLog'), loading: LoadingComponent}),
                        "authority": "operationLog",
                        "exact": true
                      }
                    ]
                  }
                ]
              },
              {
                "path": "/platformManagement",
                "name": "账号管理",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__platformManagement' */'/app/src/pages/platformManagement'), loading: LoadingComponent}),
                "icon": "UserOutlined",
                "authority": "platformManagement",
                "exact": true
              },
              {
                "path": "/accountCenter",
                "name": "账号中心",
                "icon": "UserSwitchOutlined",
                "authority": "accountCenter",
                "exact": true
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/app/src/pages/404'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/app/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/app/src/pages/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}

import myRequest from '@/services/myRequest';

export async function getContentGroupList(params) {
  return myRequest('get', '/contPoolGroup/queryList', params);
}

export async function addContentGroup(params) {
  return myRequest('post', '/contPoolGroup/add', params, { requestType: 'json' });
}

export async function editContentGroup(params) {
  return myRequest('post', '/contPoolGroup/update', params, { requestType: 'json' });
}

export async function getAllContPoolList(params) {
  return myRequest('get', '/common/queryAllContPools', params);
}

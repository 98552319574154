import myRequest from '@/services/myRequest';

// 获取rss列表
export async function getRssList(params) {
  return myRequest('get', '/channelStatistics/queryRssList', params);
}

// 获取rss列表
export async function getChannelStatisticsList(params) {
  return myRequest('get', '/channelStatistics/getChannelStatisticsList', params);
}

import { getOperationLogLists, getOperationModule } from '../requests';

const initPagination = {
  pageSize: 10,
  currentPage: 1,
  total: 0,
};

const Model = {
  namespace: 'operationLog',
  state: {
    tabledData: [],
    pagination: initPagination,
    operationModule: [],
  },
  effects: {
    *getOperationLogLists({ payload }, { call, put }) {
      const res = yield call(getOperationLogLists, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveOperationLogLists',
          payload: res.data,
        });
      }
    },
    *getOperationModule({ payload }, { call, put }) {
      const res = yield call(getOperationModule, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveOperationModule',
          payload: res.data,
        });
      }
    },
  },
  reducers: {
    saveOperationLogLists(state, { payload }) {
      const { pageNum, pageSize, total, dataInfos } = payload;
      const pagination = {
        currentPage: pageNum,
        pageSize,
        total,
      };
      return { ...state, tabledData: dataInfos, pagination };
    },
    saveOperationModule(state, { payload }) {
      if (payload) {
        const allData = JSON.parse(payload);
        const res = Object.keys(allData).map((key) => ({
          value: key,
          name: allData[key],
        }));
        return { ...state, operationModule: res };
      }
      return { ...state };
    },
  },
};
export default Model;

import myRequest from '@/services/myRequest';

// 查询媒体列表
export async function queryMediaList(params) {
  return myRequest('get', '/media/queryList', params);
}

// 添加媒体
export async function addMedia(params) {
  return myRequest('post', '/media/add', params, { requestType: 'json' });
}

// 编辑媒体
export async function updateMedia(params) {
  return myRequest('post', '/media/update', params, { requestType: 'json' });
}

import {
  queryTotalDateStats,
  queryAccountStats,
  queryWeekStats,
  queryPlatformStats,
  queryAccountInfoStats,
} from '../requests';

const Model = {
  namespace: 'dataOverview',
  state: {
    accountInfoTabled: [],
    weekStats: [],
    accountStats: [],
    platformStats: [],
    yesterdayStats: {},
  },
  effects: {
    *queryTotalDateStats({ payload }, { call, put }) {
      const res = yield call(queryTotalDateStats, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveTotalDateStats',
          payload: res.data,
        });
      }
    },
    *queryAccountInfoStats({ payload }, { call, put }) {
      const res = yield call(queryAccountInfoStats, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveAccountInfoStats',
          payload: res.data,
        });
      }
    },
    *queryAccountStats({ payload }, { call, put }) {
      const res = yield call(queryAccountStats, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveAccountStats',
          payload: res.data,
        });
      }
    },
    *resetAccountStats(_, { put }) {
      yield put({
        type: 'saveAccountStats',
        payload: [],
      });
    },
    *queryPlatformStats({ payload }, { call, put }) {
      const res = yield call(queryPlatformStats, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'savePlatformStats',
          payload: res.data,
        });
      }
    },
    *queryWeekStats({ payload }, { call, put }) {
      const res = yield call(queryWeekStats, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveWeekStats',
          payload: res.data,
        });
      }
    },
  },
  reducers: {
    saveTotalDateStats(state, { payload }) {
      return { ...state, yesterdayStats: payload };
    },
    saveAccountData(state, { payload }) {
      return { ...state, tabledData: payload };
    },
    savePlatformStats(state, { payload }) {
      if (!payload) return { ...state, platformStats: [] };
      const sum = payload.reduce((total, cur) => {
        return +cur.value + total;
      }, 0);
      const res = payload.map((item) => {
        const count = +(+item.value).toFixed(2);
        const percent = Math.round((count / sum) * 10000) / 100;
        return {
          count,
          percent,
          item: item.showName,
          key: item.platform,
        };
      });
      return { ...state, platformStats: res };
    },
    saveAccountStats(state, { payload }) {
      if (!payload) return { ...state, accountStats: [] };
      const sum = payload.reduce((total, cur) => {
        return +cur.value + total;
      }, 0);
      const res = payload.map((item) => {
        const count = +(+item.value).toFixed(2);
        const percent = Math.round((count / sum) * 10000) / 100;
        return {
          count,
          percent,
          item: item.accountName,
          key: item.accountId,
        };
      });
      return {
        ...state,
        accountStats: res,
      };
    },
    saveWeekStats(state, { payload }) {
      if (!payload) return { ...state, weekStats: [] };
      const res = Object.keys(payload).map((key) => ({
        time: key,
        value: +payload[key],
      }));
      return { ...state, weekStats: res };
    },
    saveAccountInfoStats(state, { payload }) {
      return { ...state, accountInfoTabled: payload };
    },
  },
};
export default Model;

import { getContentList, getContentById, updateStatusById } from '../requests';

const initPagination = {
  pageSize: 10,
  currentPage: 1,
  total: 0,
};

const Model = {
  namespace: 'contentManagement',
  state: {
    tabledData: [],
    pagination: initPagination,
  },
  effects: {
    *getContentList({ payload }, { call, put }) {
      const res = yield call(getContentList, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveContentList',
          payload: res.data,
        });
      }
      return res;
    },
    *getContentById({ payload }, { call }) {
      return yield call(getContentById, payload);
    },
    *updateStatusById({ payload }, { call }) {
      return yield call(updateStatusById, payload);
    },
  },
  reducers: {
    saveContentList(state, { payload }) {
      const { pageNum, pageSize, total, dataInfos } = payload;
      const pagination = {
        currentPage: pageNum,
        pageSize,
        total,
      };
      return { ...state, tabledData: dataInfos, pagination };
    },
  },
};
export default Model;

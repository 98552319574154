import myRequest from '@/services/myRequest';

// 添加子账号
export async function addSonUser(params) {
  return myRequest('post', '/user/addSonUser', params);
}
// 编辑子账号
export async function updateSonUser(params) {
  return myRequest('post', '/user/updateSonUser', params);
}
// 获取子账号列表
export async function getSonUserLists(params) {
  return myRequest('get', '/user/querySonUsers', params);
}
// 设置子账号权限
export async function setPermission(params) {
  return myRequest('get', '/user/setPermission', params);
}
// 修改子账号密码
export async function resetPassword(params) {
  return myRequest('post', '/user/updateSonPassword', params);
}
// 获取子用户权限菜单
export async function getItemTree(params) {
  return myRequest('get', '/index/getItemTree', params);
}
// 设置子用户权限菜单
export async function addAdminGroup(params) {
  return myRequest('get', '/index/addAdminGroup', params);
}
// 获取账号组列表
export async function getAccountGroupList(params) {
  return myRequest('get', '/accountGroup/queryList', params);
}
// 获取账号组列表
export async function getAccountGroupAll(params) {
  return myRequest('get', '/accountGroup/queryPublic', params);
}

// 获取内容组列表
export async function getContentGroupList(params) {
  return myRequest('get', '/contPoolGroup/queryList', params);
}

// 获取账号组列表
export async function getContentGroupAll(params) {
  return myRequest('get', '/contPoolGroup/queryPublic', params);
}

export async function getAllMonitorPanelData() {
  return myRequest('get', '/monitorPanel/list');
}

// 获取所有角色
export async function queryAllRole() {
  return myRequest('get', '/userRole/queryAll');
}

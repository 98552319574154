import { addRole, updateRole, getRoleLists, getItemTree, deleteRole } from '../requests';

const initPagination = {
  pageSize: 10,
  currentPage: 1,
  total: 0,
};

const Model = {
  namespace: 'roleManagement',
  state: {
    tabledData: [],
    pagination: initPagination,
  },
  effects: {
    *addRole({ payload }, { call }) {
      return yield call(addRole, payload);
    },
    *updateRole({ payload }, { call }) {
      return yield call(updateRole, payload);
    },
    *deleteRole({ payload }, { call }) {
      return yield call(deleteRole, payload);
    },
    *getRoleLists({ payload }, { call, put }) {
      const res = yield call(getRoleLists, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveRoleLists',
          payload: res.data,
        });
      }
    },
    *getItemTree({ payload }, { call }) {
      return yield call(getItemTree, payload);
    },
  },
  reducers: {
    saveRoleLists(state, { payload }) {
      const { pageNum, pageSize, total, dataInfos } = payload;
      const pagination = {
        currentPage: pageNum,
        pageSize,
        total,
      };
      return { ...state, tabledData: dataInfos, pagination };
    },
  },
};
export default Model;

import {
  getAccountGroupList,
  addAccountGroup,
  editAccountGroup,
  getPlatformInfo,
} from '../requests';

const initPagination = {
  pageSize: 10,
  currentPage: 1,
  total: 0,
};

const Model = {
  namespace: 'accountGroupManagement',
  state: {
    tabledData: [],
    pagination: initPagination,
    platformInfo: [],
    platformInfoData: [],
  },
  effects: {
    *getAccountGroupList({ payload }, { call, put }) {
      const res = yield call(getAccountGroupList, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveAccountList',
          payload: res.data,
        });
      }
      return res;
    },
    *getPlatformInfo({ payload }, { call, put }) {
      const res = yield call(getPlatformInfo, payload);
      if (res && res.code === 0 && res.data) {
        const parsedData = [];
        res.data.forEach((item) => {
          const findIndex = parsedData.findIndex((data) => data.value === item.platform);
          if (findIndex !== -1) {
            parsedData[findIndex].children.push({
              value: item.id,
              label: item.accountName,
              ...item,
            });
          } else {
            parsedData.push({
              value: item.platform,
              label: item.showName,
              children: [
                {
                  value: item.id,
                  label: item.accountName,
                  ...item,
                },
              ],
            });
          }
        });
        yield put({
          type: 'savePlatformInfo',
          payload: { parsedData, platformInfo: res.data },
        });
      }
      return res;
    },
    *addAccountGroup({ payload }, { call }) {
      return yield call(addAccountGroup, payload);
    },
    *editAccountGroup({ payload }, { call }) {
      return yield call(editAccountGroup, payload);
    },
  },
  reducers: {
    saveAccountList(state, { payload }) {
      const { pageNum, pageSize, total, dataInfos } = payload;
      const pagination = {
        currentPage: pageNum,
        pageSize,
        total,
      };
      return { ...state, tabledData: dataInfos, pagination };
    },
    savePlatformInfo(state, { payload }) {
      return { ...state, platformInfo: payload.parsedData, platformInfoData: payload.platformInfo };
    },
  },
};
export default Model;

// @ts-nocheck
import { Component } from 'react';
import { ApplyPluginsType } from 'umi';
import dva from 'dva';
// @ts-ignore
import createLoading from '/app/node_modules/dva-loading/dist/index.esm.js';
import { plugin, history } from '../core/umiExports';
import ModelGlobal0 from '/app/src/models/global.js';
import ModelLogin1 from '/app/src/models/login.js';
import ModelSetting2 from '/app/src/models/setting.js';
import ModelUser3 from '/app/src/models/user.js';
import ModelIndex4 from '/app/src/pages/accountCenter/models/index.js';
import ModelIndex5 from '/app/src/pages/accountGroupManagement/models/index.js';
import ModelIndex6 from '/app/src/pages/accountManagement/models/index.js';
import ModelIndex7 from '/app/src/pages/article/models/index.js';
import ModelIndex8 from '/app/src/pages/articleConversion/models/index.js';
import ModelIndex9 from '/app/src/pages/audit/models/index.js';
import ModelIndex10 from '/app/src/pages/auditRecords/models/index.js';
import ModelIndex11 from '/app/src/pages/channelStatistics/models/index.js';
import ModelIndex12 from '/app/src/pages/contentGroupManagement/models/index.js';
import ModelIndex13 from '/app/src/pages/contentManagement/models/index.js';
import ModelIndex14 from '/app/src/pages/contPoolManagement/models/index.js';
import ModelIndex15 from '/app/src/pages/correctionManagement/models/index.js';
import ModelIndex16 from '/app/src/pages/dashBoard/models/index.js';
import ModelIndex17 from '/app/src/pages/dataOverview/models/index.js';
import ModelIndex18 from '/app/src/pages/emailMessage/models/index.js';
import ModelIndex19 from '/app/src/pages/hotSearch/models/index.js';
import ModelIndex20 from '/app/src/pages/infoMarkManagement/models/index.js';
import ModelIndex21 from '/app/src/pages/material/models/index.js';
import ModelIndex22 from '/app/src/pages/mediaManagement/models/index.js';
import ModelIndex23 from '/app/src/pages/messageInfo/models/index.js';
import ModelIndex24 from '/app/src/pages/monitoring/models/index.js';
import ModelIndex25 from '/app/src/pages/operationLog/models/index.js';
import ModelIndex26 from '/app/src/pages/outboundStatistics/models/index.js';
import ModelIndex27 from '/app/src/pages/platformData/models/index.js';
import ModelIndex28 from '/app/src/pages/platformManagement/models/index.js';
import ModelIndex29 from '/app/src/pages/pushExpress/models/index.js';
import ModelIndex30 from '/app/src/pages/pushManagement/models/index.js';
import ModelIndex31 from '/app/src/pages/readInfo/models/index.js';
import ModelIndex32 from '/app/src/pages/roleManagement/models/index.js';
import ModelIndex33 from '/app/src/pages/rssManagement/models/index.js';
import ModelIndex34 from '/app/src/pages/selfBuiltManagement/models/index.js';
import ModelIndex35 from '/app/src/pages/sensitiveWords/models/index.js';
import ModelIndex36 from '/app/src/pages/sourceGroupManagement/models/index.js';
import ModelIndex37 from '/app/src/pages/sourceManagement/models/index.js';
import ModelIndex38 from '/app/src/pages/teamStatistics/models/index.js';
import ModelIndex39 from '/app/src/pages/theBlacklist/models/index.js';
import ModelIndex40 from '/app/src/pages/titleCollege/models/index.js';
import ModelIndex41 from '/app/src/pages/workerManagement/models/index.js';

let app:any = null;

export function _onCreate(options = {}) {
  const runtimeDva = plugin.applyPlugins({
    key: 'dva',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    // @ts-ignore
    ...(typeof window !== 'undefined' && window.g_useSSR ? { initialState: window.g_initialProps } : {}),
    ...(options || {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach((plugin:any) => {
    app.use(plugin);
  });
  app.model({ namespace: 'global', ...ModelGlobal0 });
app.model({ namespace: 'login', ...ModelLogin1 });
app.model({ namespace: 'setting', ...ModelSetting2 });
app.model({ namespace: 'user', ...ModelUser3 });
app.model({ namespace: 'index', ...ModelIndex4 });
app.model({ namespace: 'index', ...ModelIndex5 });
app.model({ namespace: 'index', ...ModelIndex6 });
app.model({ namespace: 'index', ...ModelIndex7 });
app.model({ namespace: 'index', ...ModelIndex8 });
app.model({ namespace: 'index', ...ModelIndex9 });
app.model({ namespace: 'index', ...ModelIndex10 });
app.model({ namespace: 'index', ...ModelIndex11 });
app.model({ namespace: 'index', ...ModelIndex12 });
app.model({ namespace: 'index', ...ModelIndex13 });
app.model({ namespace: 'index', ...ModelIndex14 });
app.model({ namespace: 'index', ...ModelIndex15 });
app.model({ namespace: 'index', ...ModelIndex16 });
app.model({ namespace: 'index', ...ModelIndex17 });
app.model({ namespace: 'index', ...ModelIndex18 });
app.model({ namespace: 'index', ...ModelIndex19 });
app.model({ namespace: 'index', ...ModelIndex20 });
app.model({ namespace: 'index', ...ModelIndex21 });
app.model({ namespace: 'index', ...ModelIndex22 });
app.model({ namespace: 'index', ...ModelIndex23 });
app.model({ namespace: 'index', ...ModelIndex24 });
app.model({ namespace: 'index', ...ModelIndex25 });
app.model({ namespace: 'index', ...ModelIndex26 });
app.model({ namespace: 'index', ...ModelIndex27 });
app.model({ namespace: 'index', ...ModelIndex28 });
app.model({ namespace: 'index', ...ModelIndex29 });
app.model({ namespace: 'index', ...ModelIndex30 });
app.model({ namespace: 'index', ...ModelIndex31 });
app.model({ namespace: 'index', ...ModelIndex32 });
app.model({ namespace: 'index', ...ModelIndex33 });
app.model({ namespace: 'index', ...ModelIndex34 });
app.model({ namespace: 'index', ...ModelIndex35 });
app.model({ namespace: 'index', ...ModelIndex36 });
app.model({ namespace: 'index', ...ModelIndex37 });
app.model({ namespace: 'index', ...ModelIndex38 });
app.model({ namespace: 'index', ...ModelIndex39 });
app.model({ namespace: 'index', ...ModelIndex40 });
app.model({ namespace: 'index', ...ModelIndex41 });
  return app;
}

export function getApp() {
  return app;
}

/**
 * whether browser env
 * 
 * @returns boolean
 */
function isBrowser(): boolean {
  return typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
}

export class _DvaContainer extends Component {
  constructor(props: any) {
    super(props);
    // run only in client, avoid override server _onCreate()
    if (isBrowser()) {
      _onCreate()
    }
  }

  componentWillUnmount() {
    let app = getApp();
    app._models.forEach((model:any) => {
      app.unmodel(model.namespace);
    });
    app._models = [];
    try {
      // 释放 app，for gc
      // immer 场景 app 是 read-only 的，这里 try catch 一下
      app = null;
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    let app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}

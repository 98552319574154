import { getChannelStatisticsList, getRssList } from '../requests';

const initPagination = {
  pageSize: 20,
  currentPage: 1,
  total: 0,
};

const Model = {
  namespace: 'channelStatistics',
  state: {
    tabledData: [],
    pagination: initPagination,
    rssList: [],
  },
  effects: {
    *getChannelStatisticsList({ payload }, { call, put }) {
      const res = yield call(getChannelStatisticsList, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveChannelStatisticsList',
          payload: res.data,
        });
      }
    },
    *getRssList({ payload }, { call, put }) {
      const res = yield call(getRssList, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveRssList',
          payload: res.data,
        });
      }
    },
  },
  reducers: {
    saveChannelStatisticsList(state, { payload }) {
      const { pageNum, pageSize, total, dataInfos, totalData } = payload;
      const pagination = {
        currentPage: pageNum,
        pageSize: pageSize + 1,
        total,
      };
      if (totalData && dataInfos.length) {
        dataInfos.push({ ...totalData, id: Math.random(), title: '合计' });
      }
      return { ...state, tabledData: dataInfos, pagination };
    },
    saveRssList(state, { payload }) {
      return { ...state, rssList: payload };
    },
  },
};
export default Model;

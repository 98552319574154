import { Settings } from '@ant-design/pro-layout';

type DefaultSettings = Settings & {
  pwa: boolean;
};

const defaultSettings: DefaultSettings = {
  primaryColor: '#17BC97',
  contentWidth: 'Fluid',
  headerTheme: 'light',
  fixedHeader: true,
  fixSiderbar: false,
  colorWeak: true,
  menu: {
    locale: false,
  },
  title: '绿萝',
  pwa: false,
  iconfontUrl: '',
};

export type { DefaultSettings };

export default defaultSettings;

import myRequest from '@/services/myRequest';

// 内容管理列表
export async function getContentList(params) {
  return myRequest('get', '/audit/queryHumanAuditContent', params);
}

// 获取详情
export async function getContentById(params) {
  return myRequest('get', '/auditContent/queryAuditContentById', params);
}

// 获取所有子账号信息
export async function queryUser(params) {
  return myRequest('get', '/user/queryUser', params);
}

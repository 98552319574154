import myRequest from '@/services/myRequest';

// 获取rss列表
export async function queryRssInfo(params) {
  return myRequest('get', '/statistics/queryRssInfo', params);
}

// 获取统计数据
export async function queryRssOutputInfo(params) {
  return myRequest('get', '/statistics/queryRssOutputHistogramInfos', params);
}

// 获取输出数据
export async function queryRssOutputList(params) {
  return myRequest('get', '/statistics/queryRssOutputList', params);
}

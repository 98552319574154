// @ts-nocheck

import HomeOutlined from '@ant-design/icons/HomeOutlined';
import LineChartOutlined from '@ant-design/icons/LineChartOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import AuditOutlined from '@ant-design/icons/AuditOutlined';
import ProfileOutlined from '@ant-design/icons/ProfileOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import DiffOutlined from '@ant-design/icons/DiffOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import UserSwitchOutlined from '@ant-design/icons/UserSwitchOutlined'

export default {
  HomeOutlined,
LineChartOutlined,
EditOutlined,
AuditOutlined,
ProfileOutlined,
TeamOutlined,
DiffOutlined,
UserOutlined,
UserSwitchOutlined
}
    
import { parse } from 'querystring';
import pathRegexp from 'path-to-regexp';

/* eslint no-useless-escape:0 import/prefer-default-export:0 */
const reg =
  /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
export const isUrl = (path) => reg.test(path);
export const isAntDesignPro = () => {
  if (ANT_DESIGN_PRO_ONLY_DO_NOT_USE_IN_YOUR_PRODUCTION === 'site') {
    return true;
  }

  return window.location.hostname === 'preview.pro.ant.design';
}; // 给官方演示站点用，用于关闭真实开发环境不需要使用的特性

export const isAntDesignProOrDev = () => {
  const { NODE_ENV } = process.env;

  if (NODE_ENV === 'development') {
    return true;
  }

  return isAntDesignPro();
};
export const getPageQuery = () => parse(window.location.href.split('?')[1]);
/**
 * props.route.routes
 * @param router [{}]
 * @param pathname string
 */

export const getAuthorityFromRouter = (router = [], pathname) => {
  const authority = router.find(({ path }) => path && pathRegexp(path).exec(pathname));
  if (authority) return authority;
  return undefined;
};
export const getRouteAuthority = (path, routeData) => {
  let authorities;
  routeData.forEach((route) => {
    // match prefix
    if (pathRegexp(`${route.path}/(.*)`).test(`${path}/`)) {
      if (route.authority) {
        authorities = route.authority;
      } // exact match

      if (route.path === path) {
        authorities = route.authority || authorities;
      } // get children authority recursively

      if (route.routes) {
        authorities = getRouteAuthority(path, route.routes) || authorities;
      }
    }
  });
  return authorities;
};

export const getContPoolNameById = (ids, allContPoolList) => {
  if (!allContPoolList) return [];
  const allContPool = [];
  ids.forEach((id) => {
    const findContPool = allContPoolList.find((contPool) => contPool.contPoolId === id);
    if (findContPool) {
      allContPool.push({
        name: findContPool.contPoolName,
        id: findContPool.contPoolId,
      });
    }
  });
  return allContPool;
};

export const getWorkerNameById = (ids, wrokers) => {
  if (!wrokers) return [];
  const allEditors = [];
  ids.forEach((id) => {
    const findWorker = wrokers.find((item) => item.workerId === id);
    if (findWorker) {
      allEditors.push({
        name: findWorker.workerName,
        id: findWorker.workerId,
      });
    }
  });
  return allEditors;
};

export const fileToBuf = (file, cb) => {
  const fr = new FileReader();
  const filename = file.name;
  fr.readAsArrayBuffer(file);
  fr.addEventListener(
    'loadend',
    (e) => {
      const buf = e.target.result;
      cb(buf, filename);
    },
    false,
  );
};

export const compileStyle = (node) => {
  [].forEach.call(node.childNodes, (child) => {
    if (child.removeAttribute && typeof child.removeAttribute === 'function') {
      child.removeAttribute('style');
      child.removeAttribute('width');
      child.removeAttribute('height');
    }
    if (child.firstElementChild) {
      compileStyle(child);
    }
  });
};

import myRequest from '@/services/myRequest';

// 获取操作记录列表
export async function getOperationLogLists(params) {
  return myRequest('get', '/adminLog/queryList', params);
}

// 获取操作记录列表
export async function getOperationModule(params) {
  return myRequest('get', '/adminLog/queryModule', params);
}

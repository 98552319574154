import { addReadInfo, getReadInfoList } from '../requests';

const initPagination = {
  pageSize: 10,
  currentPage: 1,
  total: 0,
};

const Model = {
  namespace: 'readInfo',
  state: {
    tabledData: [],
    pagination: initPagination,
  },
  effects: {
    *getReadInfoList({ payload }, { call, put }) {
      const res = yield call(getReadInfoList, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveReadInfoList',
          payload: res.data,
        });
      }
    },
    *addReadInfo({ payload }, { call }) {
      return yield call(addReadInfo, payload);
    },
  },
  reducers: {
    saveReadInfoList(state, { payload }) {
      return { ...state, tabledData: payload };
    },
  },
};
export default Model;

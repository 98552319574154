import { queryContentByInfoId } from '@/pages/article/requests';
import { getArticleLists, deleteArticle } from '../requests';

const initPagination = {
  pageSize: 10,
  currentPage: 1,
  total: 0,
};

const Model = {
  namespace: 'selfBuiltManagement',
  state: {
    contentLists: [],
    pagination: initPagination,
  },
  effects: {
    *getArticleLists({ payload }, { call, put }) {
      const res = yield call(getArticleLists, payload);
      if (res && res.code === 0) {
        const { pageCount, pageNum } = res.data;
        if (pageNum !== 1 && pageCount < pageNum) {
          const newRes = yield call(getArticleLists, { ...payload, currentPage: pageCount });
          if (res && res.code === 0) {
            yield put({
              type: 'saveArticleLists',
              payload: newRes.data,
            });
          }
        } else {
          yield put({
            type: 'saveArticleLists',
            payload: res.data,
          });
        }
      }
      return res;
    },
    *deleteArticle({ payload }, { call }) {
      return yield call(deleteArticle, payload);
    },
    *rollbackArticle({ payload }, { call }) {
      return yield call(deleteArticle, payload);
    },
    *queryArticleByInfoId({ payload }, { call }) {
      // 获取自建
      return yield call(queryContentByInfoId, payload);
    },
  },
  reducers: {
    saveArticleLists(state, { payload }) {
      const { pageNum, pageSize, total, dataInfos } = payload;
      const pagination = {
        currentPage: pageNum,
        pageSize,
        total,
      };
      return { ...state, contentLists: dataInfos, pagination };
    },
  },
};
export default Model;

import { getHotSearchList, queryHotContent } from '../requests';

const initPagination = {
  pageSize: 10,
  currentPage: 1,
  total: 0,
};

const Model = {
  namespace: 'hotSearch',
  state: {
    tabledData: [],
    pagination: initPagination,
  },
  effects: {
    *getHotSearchList({ payload }, { call, put }) {
      const res = yield call(getHotSearchList, payload);
      if (res && res.code === 0 && res.data) {
        const data = JSON.parse(res.data);
        yield put({
          type: 'saveHotSearchLists',
          payload: data,
        });
      }
    },
    *queryHotContent({ payload }, { call, put }) {
      const res = yield call(queryHotContent, { title: payload.title, itemType: payload.itemType });
      if (res && res.code === 0) {
        yield put({
          type: 'setRelatedLists',
          payload: {
            data: res.data,
            rankIndex: payload.rankIndex,
          },
        });
      }
    },
  },
  reducers: {
    saveHotSearchLists(state, { payload }) {
      return { ...state, tabledData: payload };
    },
    setRelatedLists(state, { payload }) {
      const { tabledData } = state;
      const index = tabledData.findIndex((item) => item.rank === payload.rankIndex);
      tabledData[index].relatedInfos = payload.data;
      return { ...state, tabledData };
    },
  },
};
export default Model;

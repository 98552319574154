import { querySourceList, addSource, updateSource, batchUpdateSource, querySourceGroupList } from '../requests';

const initPagination = {
  pageSize: 100,
  currentPage: 1,
  total: 0,
};

const Model = {
  namespace: 'sourceManagement',
  state: {
    tabledData: [],
    pagination: initPagination,
    sourceGroupList: [],
  },
  effects: {
    *querySourceList({ payload }, { call, put }) {
      const res = yield call(querySourceList, payload);
      if (res && res.code === 0) {
        yield put({ type: 'saveSourceList', payload: res.data });
      }
    },
    *addSource({ payload }, { call }) {
      return yield call(addSource, payload);
    },
    *updateSource({ payload }, { call }) {
      return yield call(updateSource, payload);
    },
    *batchUpdateSource({ payload }, { call }) {
      return yield call(batchUpdateSource, payload);
    },
    *querySourceGroupList({ payload }, { call, put }) {
      const res = yield call(querySourceGroupList, payload);
      if (res && res.code === 0) {
        yield put({ type: 'saveSourceGroupList', payload: res.data });
      }
    },
  },
  reducers: {
    saveSourceList(state, { payload }) {
      const { pageNum, pageSize, total, dataInfos } = payload;
      const pagination = {
        currentPage: pageNum,
        pageSize,
        total,
      };
      return { ...state, tabledData: dataInfos, pagination };
    },
    saveSourceGroupList(state, { payload }) {
      return { ...state, sourceGroupList: payload };
    },
  },
};
export default Model;

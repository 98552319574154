import {
  getPushList,
  createPush,
  queryRssInfo,
  queryAuditContentById,
  isPushable,
} from '../requests';

const initPagination = {
  pageSize: 10,
  currentPage: 1,
  total: 0,
};

const Model = {
  namespace: 'pushManagement',
  state: {
    rssInfo: [],
    tabledData: [],
    pagination: initPagination,
  },
  effects: {
    *getPushList({ payload }, { call, put }) {
      const res = yield call(getPushList, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'savePushList',
          payload: res.data,
        });
      }
    },

    *createPush({ payload }, { call }) {
      return yield call(createPush, payload);
    },

    *isPushable({ payload }, { call }) {
      return yield call(isPushable, payload);
    },

    *queryAuditContentById({ payload }, { call }) {
      return yield call(queryAuditContentById, payload);
    },

    *queryRssInfo({ payload }, { call, put }) {
      const res = yield call(queryRssInfo, payload);
      if (res && res.code === 0) {
        yield put({
          type: 'saveRssInfo',
          payload: res.data,
        });
      }
    },
  },

  reducers: {
    savePushList(state, { payload }) {
      const { pageNum, pageSize, total, dataInfos } = payload;
      const pagination = {
        currentPage: pageNum,
        pageSize,
        total,
      };
      return { ...state, tabledData: dataInfos, pagination };
    },
    saveRssInfo(state, { payload }) {
      return { ...state, rssInfo: payload };
    },
  },
};
export default Model;
